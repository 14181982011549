import request from './../request.js'

/** -----------二维码管理页面 ----------------*/
// 列表渲染以及查询 /dev-api/iot/qrcode/pageList
export function qrcodePageList(query) {
  return request({
    url: '/iot/qrcode/pageList.do?',
    method: 'get',
    params: query
  })
}

// 新增/dev-api/iot/qrcode/add
export function qrcodeAdd(data) {
    return request({
      url: '/iot/qrcode/add.do?',
      method: 'post',
      data: data
    })
  }
// 修改/dev-api/iot/qrcode/save
export function qrcodeSave(data) {
  return request({
    url: '/iot/qrcode/save.do?',
    method: 'post',
    data: data
  })
}

// 启动和停止/dev-api/iot/qrcode/enable
export function qrcodeEnable(data) {
  return request({
    url: '/iot/qrcode/enable.do?',
    method: 'post',
    data: data
  })
}
// 删除/iot/qrcode/remove
export function qrcodeRemove(data) {
  return request ({
      url:'iot/qrcode/remove.do?',
      method:'post',
      data:data,
      timeout:20000,
      headers:{'Content-Type': 'multipart/form-data'}
  })
}

/** -----------关联页面 ----------------*/
// 列表渲染以及查询 /dev-api/iot/qrcode/detail/pageList
export function detailPageList(query) {
  return request({
    url: '/iot/qrcode/detail/pageList.do?',
    method: 'get',
    params: query
  })
}

// // 批量导入下载文件模板/dev-api/iot/qrcode/detail/importTemplate
export function importTemplate(data){
  return request({
    url: '/iot/qrcode/detail/importTemplate.do?',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

// 批量新增 api/iot/qrcode/detail/import
export function detailImport(params) {
  return request ({
      url:'iot/qrcode/detail/import.do?',
      method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}

// 单个删除/dev-api/iot/qrcode/detail/deleteOne
export function deleteDeleteOne(params) {
  return request({
    url: '/iot/qrcode/detail/deleteOne.do?',
    method: 'post',
    params
  })
}
// 批量删除/dev-api/iot/qrcode/detail/delete
export function deleteDelete(query) {
  return request({
    url: '/iot/qrcode/detail/delete.do?',
    method: 'post',
    params:query
  })
}

// 单个新增 /dev-api/iot/qrcode/detail/add
export function detailAdd(params) {
  return request({
    url: '/iot/qrcode/detail/add.do?',
    method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}
